import React, { useState } from 'react';
import './App.css';
import styled from 'styled-components';
import { Header } from './Components/Header';
import { COLORS, appColor } from './Data/theme';
import { Footer } from './Components/Footer';
import { Typewriter } from './Components/Typewriter';
import { Carousel } from './Components/Carousel';
import { dndImages, growthImages, petopiaImages } from './Data/images';

export interface AppProps {
  isDarkMode: boolean;
}


const AppWrapper = styled.section<AppProps>`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => !props.isDarkMode ? COLORS[appColor].primary : COLORS.dark.primary};

  text-align: center;
`


const AboutWrapper = styled.div<AppProps>`
  margin-top: 80px;
  scroll-margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 250px;

  //The heading
  span {
    padding: 40px 0px;
    max-width: 95vw;
    font-size: min(max(22px, 2.3vw), 40px);
    font-style: italic;
    font-weight: 600;

    color: ${props => !props.isDarkMode ? COLORS[appColor].text : COLORS.dark.text};
  }


  pre {
    max-width: 95vw;
    white-space: pre-wrap;
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }


  .cursor{
    animation:1s blink step-end infinite
  }
  @keyframes blink{
    from,to{color:transparent}50%{color:#000}
    }
  @-moz-keyframes blink{
    from,to{color:transparent}50%{color:#000}
  }
  @-webkit-keyframes blink{
    from,to{color:transparent}50%{color:#000}
  }
  @-ms-keyframes blink{
    from,to{color:transparent}50%{color:#000}
  }
  @-o-keyframes blink{
    from,to{color:transparent}50%{color:#000}
  }
          
`

const SectionWrapper = styled.div<AppProps>`
  display: flex;
  flex-direction: column;

  scroll-margin-top: 80px;

  a {
    font-family: monospace;
    color: darkslategray;
    text-decoration: none;
    font-weight: bolder;

    background:
    //default: 
    //rgba(100, 200, 200, 1),
    //rgba(100, 200, 200, 1)
      linear-gradient(
        to right,
        rgba(178,216,216, 0),
        rgba(0,76,76, 0.8)
      ),
      
      /*
        default:
        rgba(255, 0, 0, 1),
        rgba(255, 0, 180, 1),
        rgba(0, 100, 200, 1)
      */
      linear-gradient(
        to right,
        rgba(102,178,178, 1),
        rgba(0,128,128, 1),
        rgba(0,76,76, 1)
    );
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;

    &:hover {
      background-size: 0 3px, 100% 3px;
    }
  }


  #hatchy {
    img {
      border: rgba(255, 255, 255, 0.15) solid 3px;
    }
  }

  .desktop img {
    max-height: 650px;
    max-width: 550px;

    /* @media only screen and (max-width: 850px){
      max-height: 50vh;
      max-width: 50vw;
    } */
  }

  .desktop {
    @media only screen and (max-width: 850px){
      flex-direction: column;

      img {
        max-width: 90vw;
        max-height: 50vh;
      }
    }
  }


`

const ProjectWrapper = styled.div<AppProps>`
  background-color: ${props => !props.isDarkMode ? COLORS[appColor].secondary : COLORS.dark.secondary};
  display: flex; 
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 300px;

  img {
    display: flex;
    border-radius: 20px;    
    height: auto;
    width: auto;

    max-height: 300px;
    max-width: 200px;
  }

  &.logo img{
    @media only screen and (max-width: 850px){
      max-width: 150px;
    }
  }
  
  :nth-child(2n) {
    background-color: ${props => !props.isDarkMode ? COLORS[appColor].tertiary : COLORS.dark.tertiary};

    flex-direction: row-reverse;
    @media only screen and (max-width: 850px){
      &.desktop {
        flex-direction: column;
      }
    }
  }

  p {
    /* background-color: red;
    width: 100%; */
    color: #1c221d;
    font-size: min(max(18px, 2vw), 28px);
    padding: 0px 3vw;
    max-width: 70vw;
  }

  /* &&.force-2-column {
    display: block;
    background-color: red;
    font-size: xx-large;
  } */
`

function App() {

  const [isDarkMode, setIsDarkMode] = useState(false);


  const aboutMeString = `Hi I'm Ana, a junior software developer from Australia. I design & build web and mobile applications. \nBelow are some of my completed/in progress projects:`

  return (
    <AppWrapper isDarkMode={isDarkMode}>
      <Header isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
      <AboutWrapper isDarkMode={isDarkMode} id="about">
        {/* <h1 className='typewriter'>Hi, I'm Ana a software developer from Australia. I design & build web and mobile apps.<br />
        Below are some of my projects:
        </h1> */}

        <Typewriter text={aboutMeString} />

      </AboutWrapper>
      <SectionWrapper isDarkMode={isDarkMode} id="projects">
        <ProjectWrapper isDarkMode={isDarkMode} className='force-2-column'>
          <Carousel images={growthImages} />
          <p>Growth is a habit tracking Progressive Web App (PWA) built with React, Typescript and Material UI (MUI) for consistent styling/theming.
            <br />Growth's backend server is created with Node JS, Sequelize and PostgreSQL. </p>
        </ProjectWrapper>
        <ProjectWrapper isDarkMode={isDarkMode} className='force-2-column'>
          <Carousel images={petopiaImages} />
          <p>Petopia is a gamified web app built with React and TypeScript, which is targeted towards language learning.
            <br />Petopia's backend server was created with Node JS, Sequelize and PostgreSQL.  </p>
        </ProjectWrapper>

        <ProjectWrapper isDarkMode={isDarkMode} className="logo">
          <img src='./images/spire_logo.png' alt='Spire Tech logo' /><p><a href="https://spire.tech/">Spire Tech</a> is a company that works with clients to build web and mobile software.
            My responsibilities in Spire Tech include in assisting/developing the development of the main website along with other client web and mobile apps. </p>
        </ProjectWrapper>
        <ProjectWrapper isDarkMode={isDarkMode} className='desktop'>
          <img src='./images/stable-diffusion.png' alt='Stable Diffusion project' />
          <p>After gathering quite a bit of curiosity with AI Art, I made a quick personal web app with React to help organise some of the AI Art data.
            The app was created with the help of MUI and the data retrieved from a public RESTful Api.
          </p>
        </ProjectWrapper>
        <ProjectWrapper isDarkMode={isDarkMode} className='force-2-column'>
          <Carousel images={dndImages} />
          <p>A <a href="https://dnd.achewbaca.me/natalia">D&D Character Sheet Manager</a> for the popular Dungeons and Dragons Tabletop Roleplaying Game.
            <br /> This was my first solo project, and I was lucky to have advise from a friend to get started (who also introduced me to React).</p>
        </ProjectWrapper>
        <ProjectWrapper isDarkMode={isDarkMode} className='desktop' id='hatchy'>
          <img src='./images/hatchy.jpg' alt='Hatchy Pocket game menu screenshot' />
          <p>Hatchy World: Survivors was a mobile arcade style game built in Unity Engine and with C# using NFT characters from Hatchy Pocket. <br />
            I primarily focused on creating the screen-responsive UI/GUI and hooking it up with the data from the game.
          </p>
        </ProjectWrapper>
      </SectionWrapper>
      <Footer isDarkMode={isDarkMode} />
    </AppWrapper>
  );
}

export default App;
