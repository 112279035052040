import { BiArrowFromBottom } from "react-icons/bi"
import styled from "styled-components"
import { COLORS, appColor } from "../Data/theme";

interface Props {
    isDarkMode?: boolean;
}
const BackToTopWrapper = styled.button<Props>`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: fit-content;
    margin-top: 10px;
    width: fit-content;
    background-color: inherit;
    border: none;
    color: ${props => !props.isDarkMode ? COLORS[appColor].primary : COLORS.dark.primary};
    cursor: pointer;

    .icon {
        font-size: 1.2em;
    }

    p {
        //font-family: "Open Sans", sans-serif;
        font-size: 0.85em;
        font-weight: 500;
        line-height: 20px;
        margin: 0px;
        padding: 0px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    &:hover {
        font-size: 0.9em;
        .icon {
            font-size: 1.3em;
        }
        color: white;
        text-shadow: 1px 1px ${props => !props.isDarkMode ? COLORS[appColor].text : COLORS.dark.text};
    }
`
export const BackToTop = () => {

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // can use other types
        })
    }

    return (
        <BackToTopWrapper onClick={goToTop}>
            <BiArrowFromBottom className="icon"/>
            <p>Back To Top</p>
        </BackToTopWrapper>
    )
}