import styled from "styled-components"
import { COLORS, appColor } from "../Data/theme"
import { AppProps } from "../App"
import { BackToTop } from "./BackToTop"

const FooterWrapper = styled.div<AppProps>`
    display: flex;
    flex-direction: column;
    //align-items: flex-start;
    padding: 20px;
    background-color: ${props => !props.isDarkMode ? COLORS[appColor].quaternary : COLORS.dark.primary};
    color: #fffbf5;
    min-height: 50px;
    flex: 0 0 fit-content;

    h4 {
        margin: 0px;
    }
`


interface Props {
    isDarkMode: boolean
}
export const Footer = ({isDarkMode}: Props) => {

    return (
        <FooterWrapper id='contact' isDarkMode={isDarkMode}>
            <h4>Contact info:</h4>
            anakatarinabaca@gmail.com <br />
            <BackToTop />
        </FooterWrapper>
    )
}