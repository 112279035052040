import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS, appColor } from '../Data/theme';
import { GiHamburgerMenu } from "react-icons/gi";
import { LuLightbulb, LuLightbulbOff } from "react-icons/lu";
import { AppProps } from '../App';

const HeaderWrapper = styled.div<AppProps>`
    display: flex;
    background-color: ${props => !props.isDarkMode ? COLORS[appColor].primary : COLORS.dark.primary};
    height: 10%;
    justify-content: flex-end;
    height: 80px;
    padding: 3px 0px;


    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;

    button {
        height: 100%;
        background: none;
        border: none;
        border-left: ${props => !props.isDarkMode ? COLORS[appColor].quaternary : COLORS.dark.quaternary} solid 2px;
        cursor: pointer;
        color: ${props => !props.isDarkMode ? COLORS[appColor].quaternary : COLORS.dark.quaternary};
    }

    h1 {
        font-family: Handlee;
        font-size: 52px;
    }
`

const IconButton = styled.button`
  font-size: 24px;
  min-width: 65px;
  width: 85px;
  min-height: 65px;
`

const MenuItem = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    min-width: 85px;
    width: fit-content;
    padding: 0px 30px;

    animation: fadeIn 0.35s;

    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    @media only screen and (max-width: 562px){
        height: 150px;
        max-width: fit-content;
        overflow: hidden;
        padding: 0 10px;
        font-size: medium;
    }
`


interface Props {
    isDarkMode: boolean;
    setIsDarkMode: (isDarkMode: boolean) => void;
}
export const Header = ({ isDarkMode, setIsDarkMode }: Props) => {

    const [isMenuHidden, setIsMenuHidden] = useState(true);

    const handleMenu = () => {
        if (isMenuHidden) {
            return setIsMenuHidden(false);
        } else {
            return setIsMenuHidden(true);
        }
    }

    const handleLight = () => {
        if (isDarkMode) {
            setIsDarkMode(false)
        } else {
            setIsDarkMode(true);
        }
    }

    const scrollTo = (id: string) => {
        window.location.replace(`/#${id}`);
    }



    return (
        <HeaderWrapper isDarkMode={isDarkMode}>

            {isMenuHidden ?
                null
                :
                <>
                    <MenuItem onClick={() => scrollTo('about')}>About Me</MenuItem>
                    <MenuItem onClick={() => scrollTo('projects')}>Projects</MenuItem>
                    <MenuItem onClick={() => scrollTo('contact')}>Contact</MenuItem>
                </>

            }

            <IconButton onClick={handleMenu}><GiHamburgerMenu /></IconButton>

            {isDarkMode ?
                <IconButton onClick={handleLight}><LuLightbulb /></IconButton>
                :
                <IconButton onClick={handleLight}><LuLightbulbOff /></IconButton>
            }

        </HeaderWrapper>
    )
}