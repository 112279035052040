
export type AppThemes = 'default' | 'dark';

export let appColor = 'default';

export const COLORS: {[name: string]: {[type:string]: string}} = {
    default: {
        primary: '#f1ebe1',
        secondary: '#c0cfb2;',
        tertiary: '#8ba888',
        quaternary: '#44624a', 
        text: '#233626'
    },
    coffeeMatcha: {
        primary: "#f2ded0", 
        secondary: "#d99b77", 
        tertiary: "#bdbf65", 
        quaternary: "#73655d", 
        text: "white"
    },
    discord: {
        primary: "#424549",
        secondary: "#36393e",
        tertiary: "#282b30", 
        quaternary: "#7289da",
        text: "white"
    },
    cappuccino: {
        primary: "#fff4e6", 
        secondary: "#be9b7b", 
        tertiary: "#854442", 
        quaternary: "#4b3832", 
        text: "#7289da"
    },
    creamCoffee: {
        primary: "#ece0d1",
        secondary: "#dbc1ac",
        tertiary: "#967259",
        quaternary: "#38220f", 
        text: "#38220f"
    },
    matchaChocolateCake: {
        primary: "#f1ebde", 
        secondary: "#ead8cb",
        tertiary: "#cdbb9d",
        quaternary: "#9a9b30", 
        text: "#45282b"
    },
    cherryChocolateCake: {
        primary: "#f1ebde", 
        secondary: "#ead8cb",
        tertiary: "#cdbb9d",
        quaternary: "#ab2361", 
        text: "#45282b"
    },
    tealChocolateCake: {
        primary: "#f1ebde", 
        secondary: "#ead8cb",
        tertiary: "#cdbb9d",
        quaternary: "#006666", 
        text: "#45282b"
    },
    dark: {
        primary: '#44624a', 
        secondary: '#8ba888',
        tertiary: '#c0cfb2;',
        quaternary: '#f1ebe1',
        text: '#fffbf5'
    }
}
