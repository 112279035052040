import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface CarouselWrapperProps {
    carouselWidth: number;
}
const CarouselWrapper = styled.div<CarouselWrapperProps>`
    display: flex;
    position: relative;
    //margin: auto auto;
    min-height: 99%;
    min-width: ${props => props.carouselWidth + 'px'};

    @media only screen and (max-width: 550px){
      min-width: ${props => props.carouselWidth - window.innerWidth/100*4 + 'px'};;
    }

    //height: 95%;
    //width: 45%;
    width: ${props => props.carouselWidth + 'px'};

    img {
        position: absolute;
        top: 0;
        right: 0;
        transition: opacity 0.5s;
    }
`

interface CarouselProps {
    images: string[],
    interval?: number, 
    carouselWidth?: number
}
export const Carousel = ({ images, interval = 3000, carouselWidth = 200 }: CarouselProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [isTransitioning, setIsTransitioning] = useState<boolean>(false);

    useEffect(() => {
        const changeImage = () => {
            setIsTransitioning(true);
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) =>
                    prevIndex === images.length - 1 ? 0 : prevIndex + 1
                );
                setIsTransitioning(false);
            }, 900)
        }

        const intervalId = setInterval(changeImage, interval);

        return () => {
            clearInterval(intervalId)
        }
    }, [images.length, interval]);

    return (
        <CarouselWrapper carouselWidth={carouselWidth} className='carousel'>
            {images.map((imageUrl, index) => (
                <img
                    key={index}
                    src={imageUrl}
                    alt="Slideshow"
                    className={index === currentImageIndex ? 'active' : ''}
                    style={{
                        opacity: index === currentImageIndex ? 1 : 0,
                    }}
                />
            ))}
        </CarouselWrapper>
    )
}