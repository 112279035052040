import React, { useState, useEffect } from 'react';


interface Props {
    text: string;
}
export const Typewriter = ({text}: Props) => {

    const [{content, carriage}, setContent] = useState({content: '', carriage: 0})

    useEffect(() => {
        if (carriage === text.length) return;
    
        const delay = setTimeout(() => {
            setContent({content: content+text[carriage], carriage: carriage+1});
            clearTimeout(delay);
        }, 0 | (Math.max(Math.random()*20-(carriage), 50)))
    }, [content, text, carriage])

    return <span><pre>{content}<span className='cursor'>|</span></pre></span>
}