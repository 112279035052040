


export const petopiaImages = [
    './images/petopia_login.png', 
    './images/petopia_home.png', 
    './images/petopia_explore.png', 
    './images/petopia_quiz.png'
]

export const growthImages = [
    './images/growth_screen0.png',
    './images/growth_screen1.png',
    './images/growth_screen2.png'
]

export const dndImages = [
    './images/d&d_screen0.png',
    './images/d&d_screen1.png'
]